import React, { useEffect, useState } from "react";
import "./App.css";
import { getClipboardCode } from "./utils/getClipboard";
import ClipboardJS from "clipboard";
const queryString = window.location.search;
const params = new URLSearchParams(queryString);
const code = params.get("code");

function App() {
  const [activationKey, setActivationKey] = useState();
  const [loading, setLoading] = useState(true);
  const [isCopied, setIsCopied] = useState({ yes: false, no: false });

  useEffect(() => {
    const getActivationKey = async code => {
      setLoading(true);
      try {
        const activationKey = await getClipboardCode(code);
        setActivationKey(activationKey);
        // x(activationKey);
        // copyToClipboard(activationKey.toString());
        setLoading(false);
      } catch (e) {
        console.log(e);
        setActivationKey(false);
        setLoading(false);
      }
    };
    if (code) {
      getActivationKey(code);
    }
  }, []);

  useEffect(() => {
    const element = document.getElementById("button");
    if (element) {
      element.click();
    }
  }, [activationKey]);

  var clipboard = new ClipboardJS(".btn");

  clipboard.on("success", function(e) {
    setIsCopied({ ...isCopied, yes: true });
    console.log("in suc");
    e.clearSelection();
  });

  clipboard.on("error", function(e) {
    console.log("in err");
    setIsCopied({ ...isCopied, no: true });
  });

  return (
    <div>
      <div
        className="App"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "250px",
          width: "100%",
          flexDirection: "column",
          marginTop: "50px"
        }}
      >
        <textarea
          id="foo"
          value={activationKey}
          style={{ position: "absolute", left: "-555555555px" }}
        />
        {loading && (
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        {!loading && !activationKey && (
          <h2
            style={{
              margin: "20px",
              textAlign: "center",
              fontFamily: "Arial, Helvetica, sans-serif",
              maxWidth: "600px"
            }}
          >
            Error retrieving the text
          </h2>
        )}
        {!loading && activationKey && (
          <h2
            style={{
              margin: "20px",
              textAlign: "center",
              fontFamily: "Arial, Helvetica, sans-serif",
              maxWidth: "600px"
            }}
          >
            We have gathered your text, copy to your clipboard with the button
            below
          </h2>
        )}
        {!loading && activationKey && (
          <button class="btn" data-clipboard-target="#foo" id="button">
            COPY TO CLIPBOARD
          </button>
        )}
      </div>
      {isCopied.no && activationKey && (
        <h4 style={{ color: "red", textAlign: "center" }}>
          There was an error copying the text to clipboard
        </h4>
      )}
      {isCopied.yes && activationKey && (
        <h4 style={{ color: "#069406", textAlign: "center" }}>
          Successfully copied the text to your clipboard!
        </h4>
      )}
    </div>
  );
}

export default App;
