import { getApiEndPoint } from '../../config/index';
import axios from 'axios';

const apiUrl = `${getApiEndPoint()}/clipboard`;

export const getClipboardCode = async code => {
  try {
    const response = await axios.get(`${apiUrl}?code=${code}`);
    const { data } = response;
    if (data) {
      return data;
    } else {
      return false;
    }
  } catch (e) {
    throw e;
  }
};
